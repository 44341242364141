import React from 'react';

const CenteredImage = ({ src, alt }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={src}
        alt={alt}
        style={{ maxWidth: '100%', width: '500px', maxHeight: '100%', objectFit: 'contain' }}
      />
    </div>
  );
};

export default CenteredImage