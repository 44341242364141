import React from 'react';
import raw from 'raw.macro';
import { Typography, Box, Grid } from '@material-ui/core';

import Markdown from './Markdown';

import resources from '../content/resources';


const ResourcesGrid = () => (
  <Box pt={2} pb={2}>
    <Grid container spacing={2} alignItems="center">
      {resources.map(resource => {
        const md = resource.md
        return (
          <>
            <Grid key={resource.alt} item xs={12} sm={12} md={6}>
              <Box>
                <img
                  style={{ border: '2px solid white', margin: '2px' }}
                  src={resource.img}
                  alt={resource.alt}
                  width="100%"
                />
              </Box>
            </Grid>
            <Grid key={resource.md} item xs={12} sm={12} md={6} display='flex' alignItems='center'>
              <Box>
                <Markdown md={raw(`../content/${md}`)}/>
              </Box>
            </Grid>
          </>
        )
      })}
    </Grid>
  </Box>
);

const Resources = () => (
  <>
    <Typography variant="h4">Bioinformatics Resources</Typography>
    <ResourcesGrid />
  </>
);

export default Resources;
