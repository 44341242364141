import React from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  whiteText: {
    color: 'white',
  },
}));

const FooterItem = ({ heading, right, children }) => {
  const isMultiColumn = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const align = isMultiColumn && right ? 'right' : 'left';
  const classes = useStyles();
  return (
    <Grid className={classes.whiteText} item xs={12} sm={6}>
      <Typography variant="subtitle2" align={align}>
        <i>{heading}</i>
      </Typography>
      <Typography variant="body1" align={align}>
        {children}
      </Typography>
    </Grid>
  );
};

export default FooterItem;
